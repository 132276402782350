import React from 'react';
import { Link } from 'gatsby';
import Page, { PageHead } from 'components/core/page';
import { HeadTags } from 'util/constants';
import * as styles from 'styles/pages/signup-home.module.scss';

export function Head() {
  return (
    <PageHead>
      <title id={HeadTags.siteTitle}>Catalün Village DJ Signup</title>
    </PageHead>
  );
}

function SignupHomePage() {
  return (
    <Page className={styles.SignupHome}>
      <section className={styles.pageCenter}>
        <h2 className={styles.smallHeading}>Join Catalün Village</h2>
        <p className={styles.subtext}>
          <span>Apply to work and play with us!</span>
        </p>
        <section className={styles.boxLinks}>
          <Link to="/camp-signup">Lünar Outpost Camp Signup</Link>
          <br />
          <Link to="/dj-signup">DJ Signup</Link>
          <br />
          <Link to="/workshop-signup">
            Signup to host
            <br />
            Workshops and Perfomances
          </Link>
        </section>
      </section>
    </Page>
  );
}

export default SignupHomePage;
